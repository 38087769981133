import React, {Component} from 'react';
import ApiReader from '../../Lib/Api/ApiReader';
import TeacherIcon from '../Icons/TeacherIcon';

import '../../Styles/FilteredList.css';

class TeachersList extends Component {
	constructor( props )
	{
		super( props );
		this.state = {
			teachers: []
		};
	}

	componentDidMount()
	{
		this.fetchApi();
	}

	fetchApi()
	{
		const apiReader = new ApiReader();
		apiReader.read(
			'teachers',
			( responseData ) => {
				this.setState(
					{
						teachers: responseData.teachers
					}
				);
			}
		);
	}

	getNavigateToHandler( id )
	{
		return () => {
			const { history } = this.props;
			history.push( '/teachers/' + id );
		};
	}

	render()
	{
		const { teachers } = this.state;

		return (
			<ul className={`filteredList`}>
				{
					teachers.map(
						( teacherFetched ) => {
							return (
								<div key={teacherFetched.id.toString()} onClick={this.getNavigateToHandler( teacherFetched.id )} className={`filteredListItem`}>
									<TeacherIcon gender={teacherFetched.gender}/>
									<span className={`iconized`}>{teacherFetched.forename} {teacherFetched.lastname}</span>
								</div>
							);
						}
					)
				}
			</ul>
		);
	}
}

export default TeachersList;
