import {faGenderless, faMars, faVenus} from '@fortawesome/free-solid-svg-icons';

class GenderIconDeterminator {
	static determine( gender )
	{
		let genderIcon;
		switch ( gender )
		{
			case 'female':
			{
				genderIcon = faVenus;
				break;
			}
			case 'male':
			{
				genderIcon = faMars;
				break;
			}
			default:
			{
				genderIcon = faGenderless;
				break;
			}
		}

		return genderIcon;
	}
}

export default GenderIconDeterminator;
