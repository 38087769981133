import React from 'react';
import AppPageAbstract from '../../Lib/Components/AppPageAbstract';
import Child from '../Cards/Child';

class ChildAppPage extends AppPageAbstract {
	render()
	{
		const { childId } = this.props.match.params;
		const { history } = this.props;

		return super.render(
			<Child childId={childId} history={history}/>
		);
	}
}

export default ChildAppPage;
