import React, {Component} from 'react';
import FilterIcon from './Icons/FilterIcon';

class Filter extends Component {
	filter( keyword )
	{
		const { itemsSelector } = this.props;
		const items             = document.querySelectorAll( itemsSelector );

		items.forEach(
			( item ) => {
				item.style.display =
					-1 === item.textContent.toLowerCase().indexOf( keyword )
						? 'none'
						: 'grid';
			}
		);
	}

	getExecuteFilterHandler()
	{
		return ( event ) => {
			const keyword = event.target.value.toLowerCase();
			this.filter( keyword );
		};
	}

	getResetFilterHandler()
	{
		return ( event ) => {
			event.target.value = '';
			this.filter( '' );
		};
	}

	render( pageContent )
	{
		return (
			<div className={`filter`}>
				<input type={`text`} onKeyUp={this.getExecuteFilterHandler()} onDoubleClick={this.getResetFilterHandler()} placeholder={`Filter`} className={`keywordInput`}/>
				<FilterIcon className={`filterIcon`}/>
			</div>
		);
	}
}

export default Filter;
