import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Menu extends Component {
	getNavigateToHandler( uri )
	{
		return () => {
			const { history } = this.props;
			history.push( uri );
		};
	}

	render()
	{
		const { menuItems } = this.props;

		return (
			<nav className={`menu`}>
				{
					menuItems.map(
						( menuItemFetched ) => (
							<Link key={menuItemFetched.id} to={menuItemFetched.uri} className={`menuItem`}>
								{menuItemFetched.label}
							</Link>
						)
					)
				}
			</nav>
		);
	}
}

export default Menu;
