import React, {Component} from 'react';
import DateOfBirthIcon from '../../Icons/DateOfBirthIcon';

class DateOfBirth extends Component {
	render()
	{
		const { dateOfBirth } = this.props;

		return (
			<div className={`property`}>
				<DateOfBirthIcon/>
				<span className={`iconized`}>{dateOfBirth}</span>
			</div>
		);
	}
}

export default DateOfBirth;
