import AppPageAbstract from './AppPageAbstract';

class FilteredAppPageAbstract extends AppPageAbstract {
	componentDidMount()
	{
		const filterElement = document.querySelector( '.header' );
		filterElement.classList.add( 'withFilter' );
	}
}

export default FilteredAppPageAbstract;
