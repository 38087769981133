import React, {Component} from 'react';
import LoadingSpinner from './LoadingSpinner';

class WithLoadingSpinner extends Component {
	constructor( props )
	{
		super( props );
		this.state = {
			isLoading: false
		}
	}

	get isLoading()
	{
		const { isLoading } = this.state;
		return isLoading;
	}

	set isLoading( isLoading )
	{
		this.setState( { isLoading: isLoading } );
	}

	renderLoadingSpinner()
	{
		return (
			<LoadingSpinner/>
		)
	}

	renderContent()
	{
	}

	render()
	{
		const { isLoading } = this.state;
		if ( true === isLoading )
		{
			return this.renderLoadingSpinner();
		}
		else
		{
			return this.renderContent();
		}
	}
}

export default WithLoadingSpinner;
