import React from 'react';
import IconAbstract from '../../Lib/Components/IconAbstract';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';

class AddressIcon extends IconAbstract {
	renderIcons()
	{
		return (
			<FontAwesomeIcon icon={faMapMarkerAlt} transform={`down-1`}/>
		)
	}
}

export default AddressIcon;
