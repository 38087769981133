import React from 'react';
import {Link} from 'react-router-dom';
import ApiReader from '../../../Lib/Api/ApiReader';
import WithLoadingSpinner from '../../../Lib/Components/WithLoadingSpinner';
import TeacherIcon from '../../Icons/TeacherIcon';

class Teachers extends WithLoadingSpinner {
	constructor( props )
	{
		super( props );
		this.state = {
			teachers: []
		};
	}

	componentDidMount()
	{
		this.isLoading = true;
		this.fetchApi();
	}

	fetchApi()
	{
		const { teachers } = this.props;
		teachers.forEach(
			( teacherFetched ) => {
				const apiReader = new ApiReader();
				apiReader.readWithId(
					'teachers',
					teacherFetched.id,
					responseData => {
						this.setState(
							( state ) => {
								const updatedTeachers = [...state.teachers, responseData.teacher];

								return {
									teachers: updatedTeachers
								};
							}
						);
						this.isLoading = false;
					}
				);
			}
		);
	}

	renderContent()
	{
		const { teachers } = this.state;

		return teachers.map(
			( teacherFetched ) => {
				return (
					<div key={teacherFetched.id} className={`property`}>
						<TeacherIcon gender={teacherFetched.gender} className={`property`}/>
						<Link to={`/teachers/${teacherFetched.id}`} className={`iconized`}>{teacherFetched.forename} {teacherFetched.lastname}</Link>
					</div>
				);
			}
		);
	}
}

export default Teachers;
