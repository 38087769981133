import React, {Component} from 'react';
import Menu from './Menu';
import Filter from './Filter';

import '../Styles/Header.css';

class Header extends Component {
	constructor( props )
	{
		super( props );
		this.state = {
			menuItems: [
				{
					id:    'children',
					label: 'Children',
					uri:   '/children'
				},
				{
					id:    'parents',
					label: 'Parents',
					uri:   '/parents'
				},
				{
					id:    'teachers',
					label: 'Teachers',
					uri:   '/teachers'
				}
			]
		}
	}

	render()
	{
		const { history }   = this.props;
		const { menuItems } = this.state;

		return (
			<div className={`header`}>
				<Menu history={history} menuItems={menuItems}/>
				<Filter itemsSelector={`.filteredList .filteredListItem`}/>
			</div>
		);
	}
}

export default Header;
