import React from 'react';
import FilteredAppPageAbstract from '../../Lib/Components/FilteredAppPageAbstract';
import ParentsList from '../FilteredLists/ParentsList';

class ParentsListAppPage extends FilteredAppPageAbstract {
	render()
	{
		const { history } = this.props;

		return super.render(
			<ParentsList history={history}/>
		);
	}
}

export default ParentsListAppPage;
