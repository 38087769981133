import React from 'react';
import AppPageAbstract from '../../../Lib/Components/AppPageAbstract';

class NotFoundAppPage extends AppPageAbstract {
	render()
	{
		return super.render(
			<div className={`not-found`}>
				404 Not Found
			</div>
		);
	}
}

export default NotFoundAppPage;
