import React from 'react';
import ApiReader from '../../../Lib/Api/ApiReader';
import WithLoadingSpinner from '../../../Lib/Components/WithLoadingSpinner';
import PhoneNumberIcon from '../../Icons/PhoneNumberIcon';
import SmsIcon from '../../Icons/SmsIcon';

class PhoneNumbers extends WithLoadingSpinner {
	constructor( props )
	{
		super( props );
		this.state = {
			phoneNumbers: []
		};
	}

	componentDidMount()
	{
		this.isLoading = true;
		this.fetchApi();
	}

	fetchApi()
	{
		const { phoneNumbers } = this.props;
		phoneNumbers.forEach(
			( phoneNumberFetched ) => {
				const apiReader = new ApiReader();
				apiReader.readWithId(
					'phoneNumbers',
					phoneNumberFetched.id,
					responseData => {
						this.setState(
							( state ) => {
								const updatedPhoneNumbers = [...state.phoneNumbers, responseData.phoneNumber];

								return {
									phoneNumbers: updatedPhoneNumbers
								};
							}
						);
						this.isLoading = false;
					}
				);
			}
		);
	}

	renderContent()
	{
		const { phoneNumbers } = this.state;

		return phoneNumbers.map(
			( phoneNumberFetched ) => {
				return (
					<div key={phoneNumberFetched.id}>
						<div className={`property`}>
							<PhoneNumberIcon type={phoneNumberFetched.type}/>
							<a href={`tel:${phoneNumberFetched.number}`} className={`iconized`}>{phoneNumberFetched.number}</a>
						</div>
						{'mobile' === phoneNumberFetched.type &&
						(
							<div className={`property`}>
								<SmsIcon/>
								<a href={`sms:${phoneNumberFetched.number}`} className={`iconized`}>{phoneNumberFetched.number}</a>
							</div>
						)
						}
					</div>
				)
			}
		);
	}
}

export default PhoneNumbers;
