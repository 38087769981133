import {faMobileAlt, faPhone, faQuestion} from '@fortawesome/free-solid-svg-icons';

class PhoneNumberTypeDeterminator {
	static determine( phoneNumberType )
	{
		let typeIcon;
		switch ( phoneNumberType )
		{
			case 'fixedLine':
			{
				typeIcon = faPhone;
				break;
			}
			case 'mobile':
			{
				typeIcon = faMobileAlt;
				break;
			}
			default:
			{
				typeIcon = faQuestion;
				break;
			}
		}
		return typeIcon;
	}
}

export default PhoneNumberTypeDeterminator;
