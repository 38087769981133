import React, {Component} from 'react';
import {Swipeable} from 'react-swipeable';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import ChildrenListAppPage from './App/AppPages/ChildrenListAppPage';
import ChildAppPage from './App/AppPages/ChildAppPage';
import ParentsListAppPage from './App/AppPages/ParentsListAppPage';
import ParentAppPage from './App/AppPages/ParentAppPage';
import TeachersListAppPage from './App/AppPages/TeachersListAppPage';
import TeacherAppPage from './App/AppPages/TeacherAppPage';
import NotFoundAppPage from './App/AppPages/ErrorPages/NotFoundAppPage';

import './App.css';

class App extends Component {
	constructor( props )
	{
		super( props );
		this.state = {
			history: createBrowserHistory()
		}
	}

	swipedLeftHandler = () => {
		const { history } = this.state;
		history.goForward();
	};

	swipedRightHandler = () => {
		const { history } = this.state;
		history.goBack();
	};

	render()
	{
		return (
			<Swipeable className={`app`} onSwipedLeft={this.swipedLeftHandler} onSwipedRight={this.swipedRightHandler}>
				<BrowserRouter>
					<Switch>
						<Route exact path={`/children`} component={ChildrenListAppPage}/>
						<Route exact path={`/children/:childId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`} component={ChildAppPage}/>
						<Route exact path={`/parents`} component={ParentsListAppPage}/>
						<Route exact path={`/parents/:parentId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`} component={ParentAppPage}/>
						<Route exact path={`/teachers`} component={TeachersListAppPage}/>
						<Route exact path={`/teachers/:teacherId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`} component={TeacherAppPage}/>
						<Route exact path={`(/)`} component={ChildrenListAppPage}/>
						<Route exact component={NotFoundAppPage}/>
					</Switch>
				</BrowserRouter>
			</Swipeable>
		);
	}
}

export default App;
