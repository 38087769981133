import React from 'react';
import {Link} from 'react-router-dom';
import ApiReader from '../../../Lib/Api/ApiReader';
import WithLoadingSpinner from '../../../Lib/Components/WithLoadingSpinner';
import ParentIcon from '../../Icons/ParentIcon';

class Parents extends WithLoadingSpinner {
	constructor( props )
	{
		super( props );
		this.state = {
			parents: []
		};
	}

	componentDidMount()
	{
		this.isLoading = true;
		this.fetchApi();
	}

	fetchApi()
	{
		const { parents } = this.props;
		parents.forEach(
			( parentFetched ) => {
				const apiReader = new ApiReader();
				apiReader.readWithId(
					'parents',
					parentFetched.id,
					responseData => {
						this.setState(
							( state ) => {
								const updatedParents = [...state.parents, responseData.parent];

								return {
									parents: updatedParents
								};
							}
						);
						this.isLoading = false;
					}
				);
			}
		);
	}

	renderContent()
	{
		const { parents } = this.state;

		return parents.map(
			( parentFetched ) => {
				return (
					<div key={parentFetched.id} className={`property`}>
						<ParentIcon gender={parentFetched.gender}/>
						<Link to={`/parents/${parentFetched.id}`} className={`iconized`}>{parentFetched.forename} {parentFetched.lastname}</Link>
					</div>
				)
			}
		);
	}
}

export default Parents;
