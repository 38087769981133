import React from 'react';
import ApiReader from '../../../Lib/Api/ApiReader';
import WithLoadingSpinner from '../../../Lib/Components/WithLoadingSpinner';
import EmailIcon from '../../Icons/EmailIcon';

class Emails extends WithLoadingSpinner {
	constructor( props )
	{
		super( props );
		this.state = {
			emails: []
		};
	}

	componentDidMount()
	{
		this.isLoading = true;
		this.fetchApi();
	}

	fetchApi()
	{
		const { emails } = this.props;
		emails.forEach(
			( emailFetched ) => {
				const apiReader = new ApiReader();
				apiReader.readWithId(
					'emails',
					emailFetched.id,
					responseData => {
						this.setState(
							( state ) => {
								const updatedEmails = [...state.emails, responseData.email];

								return {
									emails: updatedEmails
								};
							}
						);
						this.isLoading = false;
					}
				);
			}
		);
	}

	renderContent()
	{
		const { emails } = this.state;

		return emails.map(
			( emailFetched ) => {
				return (
					<div key={emailFetched.id} className={`property`}>
						<EmailIcon/>
						<a href={`mailto:${emailFetched.email}`} className={`iconized`}>{emailFetched.email}</a>
					</div>
				)
			}
		);
	}
}

export default Emails;
