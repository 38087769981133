import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSync} from '@fortawesome/free-solid-svg-icons/faSync';

class LoadingSpinner extends Component {
	render()
	{
		return (
			<div className={`loadingSpinner`}>
				<FontAwesomeIcon icon={faSync} spin={true}/>
			</div>
		);
	}
}

export default LoadingSpinner;
