import React from 'react';
import IconAbstract from '../../Lib/Components/IconAbstract';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';
import {faMobileAlt} from '@fortawesome/free-solid-svg-icons';

class SmsIcon extends IconAbstract {
	renderIcons()
	{
		return (
			<>
				<FontAwesomeIcon icon={faMobileAlt} transform={`down-1`}/>
				<FontAwesomeIcon icon={faEnvelope} transform={`shrink-11 up-1`}/>
			</>
		)
	}
}

export default SmsIcon;
