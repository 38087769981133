import React from 'react';
import FilteredAppPageAbstract from '../../Lib/Components/FilteredAppPageAbstract';
import TeachersList from '../FilteredLists/TeachersList';

class TeachersListAppPage extends FilteredAppPageAbstract {
	render()
	{
		const { history } = this.props;

		return super.render(
			<TeachersList history={history}/>
		);
	}
}

export default TeachersListAppPage;
