import React from 'react';
import ApiReader from '../../../Lib/Api/ApiReader';
import WithLoadingSpinner from '../../../Lib/Components/WithLoadingSpinner';
import AddressIcon from '../../Icons/AddressIcon';

class Addresses extends WithLoadingSpinner {
	constructor( props )
	{
		super( props );
		this.state = {
			addresses: []
		};
	}

	componentDidMount()
	{
		this.isLoading = true;
		this.fetchApi();
	}

	fetchApi()
	{
		const { addresses } = this.props;
		addresses.forEach(
			( addressFetched ) => {
				const apiReader = new ApiReader();
				apiReader.readWithId(
					'addresses',
					addressFetched.id,
					responseData => {
						this.setState(
							( state ) => {
								const updatedAddresses = [...state.addresses, responseData.address];

								return {
									addresses: updatedAddresses
								};
							}
						);
						this.isLoading = false;
					}
				);
			}
		);
	}

	renderContent()
	{
		const { addresses } = this.state;

		return addresses.map(
			( addressFetched ) => (
				<div key={addressFetched.id} className={`property`}>
					<AddressIcon/>
					<a href={`https://maps.google.com/?q=${addressFetched.address}+${addressFetched.district || ''}+${addressFetched.zipCode}+${addressFetched.city}`} target={`_blank`} className={`iconized`}>
						<div>{addressFetched.address}</div>
						<div>{addressFetched.district}</div>
						<div>{addressFetched.zipCode} {addressFetched.city}</div>
					</a>
				</div>
			)
		);
	}
}

export default Addresses;
