import React from 'react';
import AppPageAbstract from '../../Lib/Components/AppPageAbstract';
import Teacher from '../Cards/Teacher';

class TeacherAppPage extends AppPageAbstract {
	render()
	{
		const { teacherId } = this.props.match.params;
		const { history }   = this.props;

		return super.render(
			<Teacher teacherId={teacherId} history={history}/>
		);
	}
}

export default TeacherAppPage;
