import React, {Component} from 'react';
import ApiReader from '../../Lib/Api/ApiReader';
import ChildIcon from '../Icons/ChildIcon';

import '../../Styles/FilteredList.css';

class ChildrenList extends Component {
	constructor( props )
	{
		super( props );
		this.state = {
			children: []
		};
	}

	componentDidMount()
	{
		this.fetchApi();
	}

	fetchApi()
	{
		const apiReader = new ApiReader();
		apiReader.read(
			'children',
			( responseData ) => {
				this.setState(
					{
						children: responseData.children
					}
				);
			}
		);
	}

	getNavigateToHandler( id )
	{
		return () => {
			const { history } = this.props;
			history.push( '/children/' + id );
		};
	}

	render()
	{
		const { children } = this.state;

		return (
			<ul className={`filteredList`}>
				{
					children.map(
						( childFetched ) => {
							return (
								<li key={childFetched.id.toString()} onClick={this.getNavigateToHandler( childFetched.id )} className={`filteredListItem`}>
									<ChildIcon gender={childFetched.gender}/>
									<div>{childFetched.forename} {childFetched.lastname}</div>
								</li>
							);
						}
					)
				}
			</ul>
		);
	}
}

export default ChildrenList;
