import React, {Component} from 'react';

class IconAbstract extends Component {
	renderIcons()
	{
	}

	render()
	{
		const mandatoryClassName = 'icon fa-layers fa-fw';
		const className          = undefined === this.props.className ? mandatoryClassName : mandatoryClassName + ' ' + this.props.className;

		return (
			<div className={className}>
				{this.renderIcons()}
			</div>
		)
	}
}

export default IconAbstract;
