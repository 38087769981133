import React from 'react';
import IconAbstract from '../../Lib/Components/IconAbstract';
import GenderIconDeterminator from '../../Lib/GenderIconDeterminator';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserFriends} from '@fortawesome/free-solid-svg-icons';

class ParentIcon extends IconAbstract {
	renderIcons()
	{
		const { gender } = this.props;

		return (
			<>
				<FontAwesomeIcon icon={faUserFriends} transform={`down-1`}/>
				<FontAwesomeIcon icon={GenderIconDeterminator.determine( gender )} transform={`shrink-7 up-4 left-11`}/>
			</>
		)
	}
}

export default ParentIcon;
