import React, {Component} from 'react';
import ApiReader from '../../Lib/Api/ApiReader';
import DateOfBirth from './Properties/DateOfBirth';
import Parents from './Properties/Parents';
import Teachers from './Properties/Teachers';
import ChildIcon from '../Icons/ChildIcon';

import '../../Styles/Card.css';

class Child extends Component {
	constructor( props )
	{
		super( props );
		this.state = {
			child: null
		}
	}

	componentDidMount()
	{
		this.fetchApi();
	}

	fetchApi()
	{
		const { childId } = this.props;
		const apiReader   = new ApiReader();
		apiReader.readWithId(
			'children',
			childId,
			( responseData ) => {
				this.setState(
					{
						child: responseData.child
					}
				);
			}
		);
	}

	render()
	{
		const { child } = this.state;
		if ( null === child )
		{
			return null;
		}

		const { history } = this.props;

		return (
			<div className={`card`}>
				<div className={`card-header`}>
					<ChildIcon gender={child.gender}/>
					<span className={`iconized`}>{child.forename} {child.lastname}</span>
				</div>
				<div className={`card-body`}>
					<div className={`propertySet`}>
						<DateOfBirth dateOfBirth={child.dateOfBirth}/>
					</div>
					{
						child.parents.length > 0 &&
						(
							<div className={`propertySet`}>
								<Parents history={history} parents={child.parents}/>
							</div>
						)
					}
					{
						child.teachers.length > 0 &&
						(
							<div className={`propertySet`}>
								<Teachers history={history} teachers={child.teachers}/>
							</div>
						)
					}
				</div>
			</div>
		);
	}
}

export default Child;
