import React, {Component} from 'react';
import ApiReader from '../../Lib/Api/ApiReader';
import PhoneNumbers from './Properties/PhoneNumbers';
import Emails from './Properties/Emails';
import Addresses from './Properties/Addresses';
import Children from './Properties/Children';
import ParentIcon from '../Icons/ParentIcon';

import '../../Styles/Card.css';

class Parent extends Component {
	constructor( props )
	{
		super( props );
		this.state = {
			parentId: props.parentId,
			parent:   null
		}
	}

	componentDidMount()
	{
		this.fetchApi();
	}

	fetchApi()
	{
		const { parentId } = this.state;
		const apiReader    = new ApiReader();
		apiReader.readWithId(
			'parents',
			parentId,
			( responseData ) => {
				this.setState(
					{
						parent: responseData.parent
					}
				);
			}
		);
	}

	render()
	{
		const { parent } = this.state;
		if ( null === parent )
		{
			return null;
		}

		return (
			<div className={`card`}>
				<div className={`card-header`}>
					<ParentIcon gender={parent.gender}/>
					<span className={`iconized`}>{parent.forename} {parent.lastname}</span>
				</div>
				<div className={`card-body`}>
					{
						parent.phoneNumbers.length > 0 &&
						(
							<div className={`propertySet`}>
								<PhoneNumbers phoneNumbers={parent.phoneNumbers}/>
							</div>
						)
					}
					{
						parent.emails.length > 0 &&
						(
							<div className={`propertySet`}>
								<Emails emails={parent.emails}/>
							</div>
						)
					}
					{
						parent.addresses.length > 0 &&
						(
							<div className={`propertySet`}>
								<Addresses addresses={parent.addresses}/>
							</div>
						)
					}
					{
						parent.children.length > 0 &&
						(
							<div className={`propertySet`}>
								<Children children={parent.children}/>
							</div>
						)
					}
				</div>
			</div>
		);
	}
}

export default Parent;
