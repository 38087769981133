import React from 'react';
import IconAbstract from '../../Lib/Components/IconAbstract';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAt} from '@fortawesome/free-solid-svg-icons';

class EmailIcon extends IconAbstract {
	renderIcons()
	{
		return (
			<FontAwesomeIcon icon={faAt} transform={`down-2`}/>
		)
	}
}

export default EmailIcon;
