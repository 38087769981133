import React, {Component} from 'react';
import Header from '../../App/Header';

class AppPageAbstract extends Component {
	render( pageContent )
	{
		const { history } = this.props;

		return (
			<>
				<Header history={history}/>
				{pageContent}
			</>
		);
	}
}

export default AppPageAbstract;
