import React from 'react';
import AppPageAbstract from '../../Lib/Components/AppPageAbstract';
import Parent from '../Cards/Parent';

class ParentAppPage extends AppPageAbstract {
	render()
	{
		const { parentId } = this.props.match.params;
		const { history }  = this.props;

		return super.render(
			<Parent parentId={parentId} history={history}/>
		);
	}
}

export default ParentAppPage;
