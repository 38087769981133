import React from 'react';
import {Link} from 'react-router-dom';
import ApiReader from '../../../Lib/Api/ApiReader';
import WithLoadingSpinner from '../../../Lib/Components/WithLoadingSpinner';
import ChildIcon from '../../Icons/ChildIcon';

class Children extends WithLoadingSpinner {
	constructor( props )
	{
		super( props );
		this.state = {
			children: []
		};
	}

	componentDidMount()
	{
		this.isLoading = true;
		this.fetchApi();
	}

	fetchApi()
	{
		const { children } = this.props;
		children.forEach(
			( childFetched ) => {
				const apiReader = new ApiReader();
				apiReader.readWithId(
					'children',
					childFetched.id,
					responseData => {
						this.setState(
							( state ) => {
								const updatedChildren = [...state.children, responseData.child];

								return {
									children: updatedChildren
								};
							}
						);
						this.isLoading = false;
					}
				);
			}
		);
	}

	renderContent()
	{
		const { children } = this.state;

		return children.map(
			( childFetched ) => {
				return (
					<div key={childFetched.id} className={`property`}>
						<ChildIcon gender={childFetched.gender}/>
						<Link to={`/children/${childFetched.id}`} className={`iconized`}>{childFetched.forename} {childFetched.lastname}</Link>
					</div>
				)
			}
		);
	}
}

export default Children;
