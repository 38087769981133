import ApiUriBuilder from './ApiUriBuilder';

class ApiReader {
	fetchApiUri( apiUri, callback )
	{
		fetch( apiUri )
			.then(
				response => response.json()
			)
			.then(
				responseBody => callback( responseBody.data )
			);
	}

	read( relativeUriName, callback )
	{
		const apiUriBuilder = new ApiUriBuilder();
		const apiUri        = apiUriBuilder.build( relativeUriName );
		this.fetchApiUri( apiUri, callback );
	}

	readWithId( relativeUriName, id, callback )
	{
		const apiUriBuilder = new ApiUriBuilder();
		const apiUri        = apiUriBuilder.buildWithId( relativeUriName, id );
		this.fetchApiUri( apiUri, callback );
	}
}

export default ApiReader;
