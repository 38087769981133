import React from 'react';
import IconAbstract from '../../Lib/Components/IconAbstract';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PhoneNumberTypeDeterminator from '../../Lib/PhoneNumberTypeDeterminator';

class PhoneNumberIcon extends IconAbstract {
	renderIcons()
	{
		const { type } = this.props;

		return (
			<FontAwesomeIcon icon={PhoneNumberTypeDeterminator.determine( type )} transform={`down-1`}/>
		)
	}
}

export default PhoneNumberIcon;
