class ApiUriBuilder {
	constructor()
	{
		this.state = {
			schema:       'https',
			host:         'api.classlist.codekandis.net',
			baseUri:      '/',
			relativeUris: {
				index:        '',
				children:     'children',
				child:        'children',
				parents:      'parents',
				parent:       'parents',
				teachers:     'teachers',
				teacher:      'teachers',
				addresses:    'addresses',
				address:      'addresses',
				phoneNumbers: 'phone-numbers',
				phoneNumber:  'phone-numbers',
				emails:       'emails',
				email:        'emails'
			}
		};
	}

	build( relativeUriName )
	{
		const { schema, host, baseUri } = this.state;
		const relativeUri               = this.state.relativeUris[relativeUriName];

		return schema + '://' + host + baseUri + relativeUri;
	}

	buildWithId( relativeUriName, id )
	{
		const { schema, host, baseUri } = this.state;
		const relativeUri               = this.state.relativeUris[relativeUriName];

		return schema + '://' + host + baseUri + relativeUri + '/' + id;
	}
}

export default ApiUriBuilder;
