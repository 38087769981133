import React, {Component} from 'react';
import ApiReader from '../../Lib/Api/ApiReader';
import PhoneNumbers from './Properties/PhoneNumbers';
import Emails from './Properties/Emails';
import Addresses from './Properties/Addresses';
import Children from './Properties/Children';
import TeacherIcon from '../Icons/TeacherIcon';

import '../../Styles/Card.css';

class Teacher extends Component {
	constructor( props )
	{
		super( props );
		this.state = {
			teacherId: props.teacherId,
			teacher:   null
		}
	}

	componentDidMount()
	{
		this.fetchApi();
	}

	fetchApi()
	{
		const { teacherId } = this.state;
		const apiReader     = new ApiReader();
		apiReader.readWithId(
			'teachers',
			teacherId,
			( responseData ) => {
				this.setState(
					{
						teacher: responseData.teacher
					}
				);
			}
		);
	}

	render()
	{
		const { teacher } = this.state;
		if ( null === teacher )
		{
			return null;
		}

		return (
			<div className={`card`}>
				<div className={`card-header`}>
					<TeacherIcon gender={teacher.gender}/>
					<span className={`iconized`}>{teacher.forename} {teacher.lastname}</span>
				</div>
				<div className={`card-body`}>
					{
						teacher.phoneNumbers.length > 0 &&
						(
							<div className={`propertySet`}>
								<PhoneNumbers phoneNumbers={teacher.phoneNumbers}/>
							</div>
						)
					}
					{
						teacher.emails.length > 0 &&
						(
							<div className={`propertySet`}>
								<Emails emails={teacher.emails}/>
							</div>
						)
					}
					{
						teacher.addresses.length > 0 &&
						(
							<div className={`propertySet`}>
								<Addresses addresses={teacher.addresses}/>
							</div>
						)
					}
					{
						teacher.children.length > 0 &&
						(
							<div className={`propertySet`}>
								<Children children={teacher.children}/>
							</div>
						)
					}
				</div>
			</div>
		);
	}
}

export default Teacher;
