import React, {Component} from 'react';
import ApiReader from '../../Lib/Api/ApiReader';
import ParentIcon from '../Icons/ParentIcon';

import '../../Styles/FilteredList.css';

class ParentsList extends Component {
	constructor( props )
	{
		super( props );
		this.state = {
			parents: []
		};
	}

	componentDidMount()
	{
		this.fetchApi();
	}

	fetchApi()
	{
		const apiReader = new ApiReader();
		apiReader.read(
			'parents',
			( responseData ) => {
				this.setState(
					{
						parents: responseData.parents
					}
				);
			}
		);
	}

	getNavigateToHandler( id )
	{
		return () => {
			const { history } = this.props;
			history.push( '/parents/' + id );
		};
	}

	render()
	{
		const { parents } = this.state;

		return (
			<ul className={`filteredList`}>
				{
					parents.map(
						( parentFetched ) => {
							return (
								<div key={parentFetched.id.toString()} onClick={this.getNavigateToHandler( parentFetched.id )} className={`filteredListItem`}>
									<ParentIcon gender={parentFetched.gender}/>
									<span className={`iconized`}>{parentFetched.forename} {parentFetched.lastname}</span>
								</div>
							);
						}
					)
				}
			</ul>
		);
	}
}

export default ParentsList;
