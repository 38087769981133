import React from 'react';
import IconAbstract from '../../Lib/Components/IconAbstract';
import GenderIconDeterminator from '../../Lib/GenderIconDeterminator';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChild} from '@fortawesome/free-solid-svg-icons';

class ChildIcon extends IconAbstract {
	renderIcons()
	{
		const { gender } = this.props;

		return (
			<>
				<FontAwesomeIcon icon={faChild} transform={`down-1`}/>
				<FontAwesomeIcon icon={GenderIconDeterminator.determine( gender )} transform={`shrink-7 down-6 left-8`}/>
			</>
		)
	}
}

export default ChildIcon;
